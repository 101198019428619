import React from 'react';

import { useResponsive } from 'client/hooks';

import { ControlPanel, Header } from 'client/components/common/mobile';
import { HelmetComponent, Icon } from 'client/components/common';
import aboutBgD from 'client/assets/about/about-bg__d-2.jpg';
import aboutBgM from 'client/assets/about/about-bg__m.png';
import storyBgD from 'client/assets/about/story-bg__d.png';
import storyBgM from 'client/assets/about/story-bg__m-new.png';
import storyBgFold from 'client/assets/about/story-bg-fold.png';
import valuesBgD from 'client/assets/about/values-bg__d.png';
import valuesBgM from 'client/assets/about/values-bg__m.png';
import cukiermanLogo from 'client/assets/about/cukierman-logo.svg';
import catalistLogo from 'client/assets/about/catalist-logo.svg';
import { BOARD, TEAM } from 'client/constants';

import css from './About.module.scss';

const About = () => {
  const [isMobile] = useResponsive(['MOBILE']);

  const onScrollToClick = () => {
    const scrolledEl = document.getElementById('story-section');
    if (scrolledEl) {
      scrolledEl.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <HelmetComponent
        title="About Us - Catalyst Investors' Club"
        description="Welcome to Catalyst Investors' Club, where we connect investors with startups through cutting-edge technology and curated content, redefining the investment experience."
        ogTitle="About Us - Catalyst Investors' Club"
        ogDescription="Welcome to Catalyst Investors' Club, where we connect investors with startups through cutting-edge technology and curated content, redefining the investment experience."
      ></HelmetComponent>
      <div className={css.pageWrapper}>
        {isMobile ? <Header className={css.pageHeader} /> : null}
        {isMobile ? <ControlPanel className={css.controlPanel} /> : null}

        <div className={css.contentWrapper}>
          <section className={css.about} style={{ backgroundImage: `url(${isMobile ? aboutBgM : aboutBgD})` }}>
            <div className={css.content}>
              <h1 className={css.mainTitle}>About US</h1>
              <p>
                Welcome to <strong>Catalyst Investors’ Club</strong>, where we are on a mission to revolutionize startup
                investment and innovation. Our multifaceted platform is designed to redefine how investors discover
                cutting-edge technologies from promising startups and growth companies. We empower investors eager to
                fund the expansion of late-stage companies, up to now only accessible to institutional venture
                capitalists.
              </p>
            </div>
            <button type="button" className={css.scrollDownBtn} onClick={onScrollToClick}>
              <Icon type="scroll-down" />
            </button>
          </section>
          <section className={css.story} id="story-section">
            <div className={css.content}>
              <h2 className={css.secondaryTitle}>Our Story</h2>
              <p>
                <strong>Catalyst Investors' Club</strong> began with a simple idea: to enable investors to invest into
                late-stage companies, a privilege only accessible through venture funds until now. We saw the need for a
                platform that not only showcases the best of the startup world but also facilitates meaningful
                connections between visionaries and ventures.
              </p>
              <p>
                With this vision in mind, we created a platform that combines a curated VOD library with an exclusive
                investors club, providing a gateway for investors to explore and invest in the most promising companies.
              </p>
            </div>
            <img src={isMobile ? storyBgM : storyBgD} alt="our story" className={css.storyBgImage} />
            <img src={storyBgFold} className={css.storyBgImageFold} alt="" />
          </section>
          <section className={css.whyUs}>
            <h2 className={css.secondaryTitle}>Why Us?</h2>
            <div className={css.content}>
              <div className={css.text}>
                <p>
                  What sets <strong>Catalyst Investor's Club</strong> apart is our rigorous selection process of the
                  companies open for investment on our platform: they have to be growth companies with proven
                  technology, be backed by internationally reputable lead investors, have a convincing business plan and
                  team, and an attractive valuation. We believe in empowering investors with the knowledge they need to
                  make confident investment decisions.
                </p>
                <p>
                  Moreover, <strong>Catalyst Investors’ Club</strong> is empowered by the two prestigious partners:
                  <strong>Cukierman & Co. Investment House</strong>, founded in 1993, with a stellar record of over $10B
                  in completed transactions, and <strong>Catalyst Investment Fund</strong>, established in 1999. The
                  fund has successfully managed four top-performing high-tech funds, boasting an impressive portfolio of
                  potential unicorn companies in Israel, managing over $450M.
                </p>

                <p>
                  This collaboration brings <strong>Catalyst Investors’ Club</strong> unmatched expertise and resources,
                  strengthening our ability to connect investors with startups and foster meaningful relationships.
                </p>
              </div>
              <div className={css.other}>
                <div className={css.quote}>
                  <p>
                    "In today's rapidly evolving market, investing in innovation is not just a strategy: it's a
                    necessity. Catalyst Investors’ Club is committed to identifying and supporting the pioneers who are
                    shaping the future."
                  </p>
                  <div className={css.quoteAuthor}>
                    Edouard Cukierman <br /> Founder of Investors’ Club
                  </div>
                </div>
                <div className={css.logos}>
                  <img src={cukiermanLogo} alt="cukierman & co logo" className={css.cukiermanLogo} />
                  <img src={catalistLogo} alt="catalist logo" className={css.catalistLogo} />
                </div>
              </div>
            </div>
          </section>
          <section className={css.values} style={{ backgroundImage: `url(${isMobile ? valuesBgM : valuesBgD})` }}>
            <h2 className={css.secondaryTitle}>Our Values</h2>
            <div className={css.list}>
              <div className={css.listItem}>
                <h6 className={css.title}>Innovation</h6>
                <p>
                  We're passionate about pushing the boundaries of what's possible, leveraging cutting-edge technology
                  to redefine the future of startup investment.
                </p>
              </div>
              <div className={css.listItem}>
                <h6 className={css.title}>Empowerment</h6>
                <p>
                  We're committed to empowering both investors and startups, providing them with the resources and
                  support they need to succeed.
                </p>
              </div>
              <div className={css.listItem}>
                <h6 className={css.title}>Integrity</h6>
                <p>
                  We hold ourselves to the highest ethical standards, conducting thorough due diligence and maintaining
                  the utmost integrity in all of our interactions.
                </p>
              </div>
              <div className={css.listItem}>
                <h6 className={css.title}>Collaboration</h6>
                <p>
                  We believe in the power of collaboration, fostering meaningful connections between investors and
                  startups to drive innovation and growth.
                </p>
              </div>
            </div>
          </section>
          <section className={css.team}>
            <h2 className={css.secondaryTitle}>The Team</h2>
            <div className={css.grid}>
              {TEAM.map((member, index) => (
                <div key={`member-${index}`} className={css.gridItem}>
                  <div className={css.photo}>
                    <img src={member.image} alt="" />
                  </div>
                  <p className={css.name}>{member.name}</p>
                  <p className={css.position}>{member.position}</p>
                </div>
              ))}
            </div>
          </section>
          <section className={css.board}>
            <h2 className={css.secondaryTitle}>The Board</h2>
            <div className={css.grid}>
              {BOARD.map((member, index) => (
                <div key={`board-member-${index}`} className={css.gridItem}>
                  <div className={css.photo}>
                    <img src={member.image} alt="" />
                  </div>
                  <p className={css.name}>{member.name}</p>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default About;
